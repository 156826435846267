import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./style.css";
import { ButtonLink } from "components/ButtonLink";
import { Img } from "components/Img";
import { Feedback } from "components/Feedback";

import {
  GiftSuggestionResultsResponse,
  GiftSuggestionResultsWithId,
} from "../types";
import {
  Container,
  Heading,
  Result,
  ResultHeading,
  ResultGroupHeading,
  ResultTag,
  AffiliateTag,
  ResultText,
  ResultImgContainer,
  ResultCtaHeading,
  ResultsFound,
  BackToTop,
  Wrapper,
  OptionHolder,
  ReviewContainer,
} from "./style";
import { Props } from "./types";
import { SvgArrow } from "svg/SvgArrow";
import { Separator } from "components/Separator";
import { StarRating } from "components/StarRating";
import { Button } from "components/Button";
import { useNavigate } from "react-router-dom";

export function Results({
  giftSuggestions,
  sendGiftBuyNowNotification,
}: Props) {
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024); // Adjust threshold as needed
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this runs only once
  function getDeliveryValue(
    delivery: GiftSuggestionResultsResponse["delivery"]
  ) {
    if (typeof delivery === "string") {
      return delivery === "Free" || delivery === "Unknown"
        ? delivery
        : `$${delivery}`;
    } else if (typeof delivery === "number") {
      return `$${delivery}`;
    } else {
      return "";
    }
  }

  const handleClick = (products: any, index: number) => {
    // console.log("handleClick more");
    // console.log(products);
    // console.log(index);
    // This will navigate to the '/about' page
    // navigate("/morelikethis");
    navigate("/morelikethis", { state: { products, index } });
  };
  function onClickBuyNow({ title, link }: { title: string; link: string }) {
    console.log("onClickBuyNow");
    sendGiftBuyNowNotification({ title, link });
  }

  const handleClickBuy = (title, link) => {
    window.location.href = "https://www.example.com";
    onClickBuyNow({
      title,
      link,
    });
  };

  function renderResultItem({
    result,
    resultNum,
    currentIndex,
    resultsTotalNum,
    group,
  }: {
    result: GiftSuggestionResultsWithId;
    resultNum: number;
    currentIndex: number;
    resultsTotalNum: number;
    group?: string;
    currency?: string;
  }) {
    const title = result.title ?? "";
    const seller = result.seller;
    const description = result.description;
    const advert = result.advert;
    const link = result.link ?? "";
    const thumbnail = result.thumbnail;
    const price = result.price;
    const delivery = result.delivery;
    const rating = result.rating;
    const reviews = result.reviews;
    const currency = result.currency;

    return (
      <>
        <SwiperSlide key={result.id}>
          <Container>
            {resultsTotalNum > 1 ? (
              <OptionHolder>
                Option {resultNum}/{resultsTotalNum > 5 ? 5 : resultsTotalNum}
              </OptionHolder>
            ) : (
              ""
            )}
            <div
              onClick={() => {
                window.open(link, "_blank");
                onClickBuyNow({
                  title,
                  link,
                });
              }}
            >
              {thumbnail ? (
                <ResultImgContainer>
                  <Img
                    src={Array.isArray(thumbnail) ? thumbnail[0] : thumbnail}
                    alt={title}
                  />
                </ResultImgContainer>
              ) : null}
              {/* <ResultGroupHeading>{group}</ResultGroupHeading> */}
              <ResultHeading>{title}</ResultHeading>
              {typeof price === "number" || delivery ? (
                <>
                  {typeof price === "number" ? (
                    <ResultCtaHeading>
                      <span>{currency}</span> ${price.toString()}
                    </ResultCtaHeading>
                  ) : null}
                  {delivery ? (
                    <ResultCtaHeading>
                      <span>Delivery:</span> {getDeliveryValue(delivery)}
                    </ResultCtaHeading>
                  ) : null}
                </>
              ) : null}
              <ReviewContainer>
                {rating ? (
                  <>
                    {rating} <StarRating rating={rating} /> (
                    {(reviews ?? 999) >= 1000
                      ? `${((reviews ?? 0) / 1000)
                          .toFixed(1)
                          .replace(/\.0$/, "")}k`
                      : reviews ?? 0}
                    )
                  </>
                ) : (
                  "No reviews yet"
                )}
              </ReviewContainer>
              <ResultTag>
                {advert && <AffiliateTag>affiliate</AffiliateTag>}
                Seller:&nbsp;<span>{seller}</span>
              </ResultTag>
              {description ? <ResultText>{description}</ResultText> : null}
              {/* {link ? (
              <ButtonLink
                href={link}
                onClick={() =>
                  onClickBuyNow({
                    title,
                    link,
                  })
                }
                target="_blank"
              >
                View now <SvgArrow />
              </ButtonLink>
            ) : null} */}
            </div>
          </Container>
        </SwiperSlide>
        <Separator />
      </>
    );
  }

  if (giftSuggestions.length) {
    const totalOptions = giftSuggestions.reduce((acc, curr) => {
      return acc + (curr.results?.length ?? 0);
    }, 0);
    return (
      <div>
        <ResultsFound>
          <Heading className="center">It’s Givving Time!</Heading>
          We created <span>{giftSuggestions.length}</span> gift ideas and found{" "}
          <span>{totalOptions}</span> seller options
        </ResultsFound>
        {giftSuggestions.map((giftSuggestion, index) => (
          <React.Fragment key={giftSuggestion.id}>
            <Result
              style={{ padding: isDesktop ? 20 : 0 }}
              key={giftSuggestion.id}
            >
              <ResultGroupHeading>
                <div
                  style={{
                    fontSize: 15,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ color: "#B0B0B0", display: "inline" }}>
                    Gift Idea {index + 1}{" "}
                  </div>
                  : {giftSuggestion.group}
                </div>

                {isDesktop ? (
                  <Button
                    onClick={() => handleClick(giftSuggestions, index)}
                    icon="search"
                    type="submit"
                    style={{
                      marginLeft: 52,
                      // marginRight: 52,
                      // marginBottom: 26,
                      padding: "15px 15px",
                      width: "150px",
                      height: "30px",
                      //   fontSize: "12px",
                    }}
                  >
                    Shop this gift idea
                  </Button>
                ) : null}
              </ResultGroupHeading>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                autoHeight={true}
                slidesPerView={isDesktop ? 5 : 1.2}
                spaceBetween={10}
                centeredSlides={isDesktop ? false : true}
              >
                {giftSuggestion.results?.map((result, i) => {
                  if (i > 4) {
                    return null; // Return early for items greater than 3, skipping them.
                  }
                  return (
                    <React.Fragment key={result.id}>
                      {renderResultItem({
                        result,
                        resultNum: i + 1,
                        currentIndex: i,
                        resultsTotalNum: giftSuggestion.results.length,
                        group: giftSuggestion.group,
                      })}
                    </React.Fragment>
                  );
                })}
              </Swiper>
              <div
                style={{
                  display: "flex",
                  paddingTop: "10px",
                  justifyContent: "center", // Horizontally centers the button
                  alignItems: "center", // Vertically centers the button
                }}
              >
                {!isDesktop ? (
                  <Button
                    onClick={() => handleClick(giftSuggestions, index)}
                    icon="search"
                    type="submit"
                    style={{
                      marginLeft: 52,
                      marginRight: 52,
                      marginBottom: 26,
                      //   width: "135px",
                      //   height: "30px",
                      //   fontSize: "12px",
                    }}
                  >
                    Shop this gift idea
                  </Button>
                ) : null}
              </div>
            </Result>
          </React.Fragment>
        ))}
        <Wrapper>
          <Feedback />
        </Wrapper>
        <BackToTop
          onClick={() => {
            window.scrollTo(0, 0); // Scroll to the top on route change
          }}
        >
          Back to top
        </BackToTop>
      </div>
    );
  } else {
    return null;
  }
}
