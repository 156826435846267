// import { useAuth0 } from "@auth0/auth0-react";
// import { Loader } from "components/Loader";
// import { Notification } from "components/Notification";
// import { Container } from "pages/GiftSuggestions/style";
import Routes from "routing/Routes";
// import Login from "pages/Login";

function App() {
  return <Routes />;
}

export default App;
