import styled, { css } from "styled-components";
import { ButtonColors } from "./index";
import { defaults, colors } from "../../theme";

export type ButtonStyleProps = {
  $color: ButtonColors;
};

export const ButtonBlockTheme = css<ButtonStyleProps>`
  font-family: ${defaults.fontFamily};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: ${colors.btnText};
  text-decoration: none;
  text-align: center;
  background-color: ${colors.btn};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.btn};
  width: 100%;
  height: 48px;
  border-radius: ${defaults.borderRadius};
  position: relative;
  padding: 0 12px;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  white-space: pre;
  transition: ${defaults.transitionFull};

  &:focus {
    box-shadow: ${defaults.focusBoxShadow};
  }

  &:hover {
    color: ${colors.btnHoverText};
    background-color: ${colors.btnHover};
    border-color: ${colors.btnHover};
  }

  &:disabled {
    cursor: not-allowed;
  }

  span {
    margin-right: 6px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  @media (min-width: 1024px) {
    // display: none;
  }
`;

export const StyledButton = styled.button<ButtonStyleProps>`
  ${ButtonBlockTheme}
`;
