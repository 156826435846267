import { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  Container,
  Disclaimer,
  GroupContainer,
  GroupedTabs,
  GroupTitle,
  SearchBar,
  Title,
} from "./style";
import { Notification } from "components/Notification";
import { Button, ButtonContainer } from "components/Button";
import { Select, Textarea, InputNumber } from "components/Form";
import { FormFields, GiftSuggestionsWithId } from "../types";
import { LikesOrDislikesValuesRaw } from "./LikesOrDislikesModal/types";
import LikesOrDislikesModal from "./LikesOrDislikesModal";
import { Props } from "./types";
import { Toggle } from "components/Toggle";
import { SvgPlus } from "svg/SvgPlus";
import { SvgPencil } from "svg/SvgPencil";
import { colors } from "theme";
import { websocketAddressV2 } from "utils/constants";
import useWebSocket from "react-use-websocket";
import { isGiftSuggestionsArray } from "../helper";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { Loader } from "components/Loader";

export const validationSchema = Yup.object().shape({
  // budget: Yup.string().required("Budget is required"),
});

const canned = {
  teen: {
    title: "Gifts for teens",
    color: "rgba(61, 64, 74, 1)",
    bcolor: "rgba(233, 233, 233, 0.8)",
  },
  boyfriend: {
    title: "Gifts for your boyfriend",
    color: "rgba(0, 111, 7, 1)",
    bcolor: "rgba(0, 111, 7, 0.1)",
  },
  girlfriend: {
    title: "Gifts for your girlfriend",
    color: "rgba(184, 58, 201, 1)",
    bcolor: "rgba(184, 58, 201, 0.1)",
  },
  parent: {
    title: "Gifts for parents",
    color: "rgba(248, 115, 41, 1)",
    bcolor: "rgba(248, 115, 41, 0.1)",
  },
  grandparent: {
    title: "Gifts for grandma",
    color: "#B6E01F",
    bcolor: "rgba(15, 73, 98, 0.5)",
  },
  kid: {
    title: "Gifts for kids",

    color: "rgba(63, 93, 220, 1)",
    bcolor: "#D0CEEC",
  },
  uncle: {
    title: "Gifts for your weird uncle",
    color: "rgba(9, 168, 173, 1)",
    bcolor: "rgba(9, 168, 173, 0.1)",
  },
};

export function Search({
  getGiftSuggestions,
  isLoadingSuggestions,
  isWebsocketReady,
  configMetaData,
  getLikesDislikes,
  likesDislikesData,
  errorData,
  loadingData,
}: Props) {
  // Store likes & dislikes selections as an object to make it easier to submit it to the API later
  const [likesSelections, setLikesSelections] = useState<
    LikesOrDislikesValuesRaw[]
  >([]);
  const [dislikesSelections, setDislikesSelections] = useState<
    LikesOrDislikesValuesRaw[]
  >([]);
  const [likesModalActive, setLikesModalActive] = useState(false);
  const [dislikesModalActive, setDislikesModalActive] = useState(false);
  const [writeItActive, setWriteItActive] = useState(false);
  const [advancedOption, setAdvancedOption] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const navigate = useNavigate();
  const [isCannedLoadingSuggestions, setIsLoadingSuggestions] = useState(false);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    websocketAddressV2,
    {
      share: false,
      shouldReconnect: () => true,
    }
  );
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024); // Adjust threshold as needed
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this runs only once
  const genderOptions = configMetaData?.gender ?? [];
  const locationOptions = configMetaData?.location ?? [];
  const ageOptions = configMetaData?.age ?? [];

  const onSubmit = async (formValues: FormFields) => {
    setHasSubmitted(true);
    const uniqueLikesArray: string[] = [];
    likesSelections.forEach((item) => {
      item.key.value.forEach((value) => {
        if (!uniqueLikesArray.includes(value)) {
          uniqueLikesArray.push(value);
        }
      });
    });

    const uniqueDislikesArray: string[] = [];
    dislikesSelections.forEach((item) => {
      item.key.value.forEach((value) => {
        if (!uniqueDislikesArray.includes(value)) {
          uniqueDislikesArray.push(value);
        }
      });
    });

    // Remove the about field if the build it toggle is active
    if (!writeItActive && advancedOption) {
      formValues.about = "";
    }
    if (formValues.budget === "") {
      formValues.budget = "200";
    }

    getGiftSuggestions({
      queryFields: {
        ...formValues,
        likes: uniqueLikesArray,
        dislikes: uniqueDislikesArray,
      },
    });
  };

  useEffect(() => {
    console.log("RECEIVE:");
    console.log(lastJsonMessage);
    if (isGiftSuggestionsArray(lastJsonMessage)) {
      console.log("isGiftSuggestionsArray true");
      console.log(lastJsonMessage);
      setIsLoadingSuggestions(false);

      const mappedGiftSuggestions: GiftSuggestionsWithId =
        lastJsonMessage.results.map((giftSuggestion) => ({
          ...(giftSuggestion.group && {
            group: giftSuggestion.group,
          }),
          results: giftSuggestion.results
            ? giftSuggestion.results.map((result) => ({
                ...result,
                id: v4(),
              }))
            : [],
          filters: giftSuggestion.filters
            ? giftSuggestion.filters.map((result) => ({
                ...result,
                id: v4(),
              }))
            : [],
          id: v4(),
        }));
      // setGiftSuggestions(mappedGiftSuggestions);
      navigate("/search", { state: { mappedGiftSuggestions } });

      // setGiftSuggestions(mappedGiftSuggestions[0]);
      // console.log("mappedGiftSuggestions");
      // // console.log(mappedGiftSuggestions[0]?.results);
      // setProductFilters([
      //   ...getProductFilters(mappedGiftSuggestions[0]?.filters),
      // ]);
      // if (prevIsLoadingSuggestions) {
      //   scroller.scrollTo("results", {
      //     duration: 500,
      //     smooth: true,
      //     offset: -100,
      //   });
      // }
    }
  }, [lastJsonMessage]);

  const cannedClicked = (key: any) => {
    console.log("canned clicked");
    console.log(key);
    setIsLoadingSuggestions(true);
    sendJsonMessage({
      canned: key,
    });
  };

  const fieldIdPrefix = "search";
  return (
    <Container>
      {/* Using style tag to hide scrollbar in WebKit browsers */}
      <style>
        {`
          div::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      {isCannedLoadingSuggestions ? (
        <Loader variant="fixed" backdrop="transparent" />
      ) : null}

      {advancedOption ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: !advancedOption ? (isDesktop ? 60 : 60) : 20,
              // marginBottom: !advancedOption ? (isDesktop ? 60 : 60) : 20,
            }}
          >
            <div style={{ fontWeight: 600, fontSize: 16 }}>
              Back to the simple version
            </div>
            <Button
              onClick={() => {
                console.log("pressed options");
                setAdvancedOption(!advancedOption);
              }}
              style={{
                padding: "8px 16px",
                marginLeft: "24px",
                width: "100px",
                height: "30px",
                // backgroundColor: "#4CAF50",
                backgroundColor: colors.blue,
                border: "none",
                color: "white",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Go back
            </Button>
          </div>
        </>
      ) : null}

      <Title
        style={{
          marginTop: isDesktop ? 50 : 50,
          marginBottom: advancedOption ? 0 : 30,
        }}
      >
        Find a gift for anyone
      </Title>

      <Formik
        enableReinitialize
        initialValues={{
          budget: "",
          who: "",
          about: "",
          location: "",
          age: "",
          likes: "",
          dislikes: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
          // const isLikesDislikesDisabled = !values.location.length;
          return (
            <form
              id="giftForm"
              style={{
                alignItems: "center",
              }}
              onSubmit={handleSubmit}
            >
              <div
                style={{
                  width: "100%",
                  justifyItems: "center",
                  display: "flex",
                  alignItems: "center" /* Vertically centers the divs */,
                  flexDirection: "column",
                }}
              >
                {!advancedOption ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        maxWidth: 600,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <SearchBar
                        style={{
                          paddingTop: isDesktop ? 30 : 10,
                          paddingBottom: isDesktop ? 30 : 10,
                          paddingLeft: isDesktop ? 30 : 0,
                          paddingRight: isDesktop ? 30 : 0,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: isDesktop ? 0 : "16px",
                            marginRight: isDesktop ? 0 : "16px",
                          }}
                        >
                          <Field
                            name="about"
                            type="text"
                            placeholder={"Write about who you’re buying for..."}
                            style={{
                              padding: "8px",
                              marginLeft: "8px",
                              // fontSize: "14px",
                              height: 50,
                              backgroundColor: "#161618",
                              flex: 1,
                              outline: "none" /* Remove default outline */,
                              border: "none",
                              color: "white",
                              fontSize: 17,
                              fontWeight: 500,

                              // onKeyDown={handleKeyPress} // Trigger submission on Enter key

                              // border: none,
                            }}
                          />

                          <Button
                            type="submit"
                            // onClick={handleButtonClick}
                            isLoading={isLoadingSuggestions}
                            loadingData={loadingData}
                            disabled={!isWebsocketReady || isSubmitting}
                            style={{
                              padding: "8px 16px",
                              marginLeft: "8px",
                              width: isDesktop ? "100px" : "60px",
                              height: isDesktop ? "50px" : "40px",
                              // display: isDesktop ? "block" : "none",
                              // backgroundColor: "#4CAF50",
                              backgroundColor: colors.blue,
                              border: "none",
                              color: "white",
                              borderRadius: "50px",
                              cursor: "pointer",
                            }}
                          >
                            Go
                          </Button>
                        </div>
                      </SearchBar>
                      {/* {isDesktop ? null : (
                        <Button
                          type="submit"
                          // onClick={handleButtonClick}
                          isLoading={isLoadingSuggestions}
                          loadingData={loadingData}
                          disabled={!isWebsocketReady || isSubmitting}
                          style={{
                            padding: "8px 16px",
                            marginLeft: "8px",
                            width: "100px",
                            height: "50px",
                            // backgroundColor: "#4CAF50",
                            backgroundColor: colors.blue,
                            border: "none",
                            color: "white",
                            borderRadius: "50px",
                            cursor: "pointer",
                          }}
                        >
                          Go &#127873;
                        </Button>
                      )} */}
                    </div>
                  </>
                ) : null}
                {advancedOption ? null : (
                  <div
                    style={{
                      overflow: "scroll",
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: !isDesktop ? "flex-start" : "center",
                      alignItems: !isDesktop ? "flex-start" : "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: isDesktop ? "center" : "flex-start",
                        alignItems: "center",
                        marginTop: 40,
                        marginBottom: 20,
                        gap: 20,
                        minWidth: 700,
                        width: isDesktop ? "50%" : "100%",
                      }}
                    >
                      {Object.keys(canned).map((key: any) => {
                        const value = canned[key as keyof typeof canned];
                        return (
                          <Button
                            style={{
                              color: value.color,
                              backgroundColor: value.bcolor,
                              borderRadius: "35px",
                              padding: "0px 16px",
                              width: "auto",
                              border: "none",
                              height: 30,
                            }}
                            onClick={() => cannedClicked(key)}
                          >
                            {value.title}
                          </Button>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: !advancedOption ? (isDesktop ? 120 : 60) : 20,
                    marginBottom: !advancedOption ? (isDesktop ? 120 : 60) : 20,
                  }}
                >
                  {!advancedOption ? (
                    <>
                      <div style={{ fontWeight: 600, fontSize: 16 }}>
                        Or try the advanced version
                      </div>
                      <Button
                        onClick={() => {
                          console.log("pressed options");
                          setAdvancedOption(!advancedOption);
                        }}
                        style={{
                          padding: "8px 16px",
                          marginLeft: "24px",
                          width: "100px",
                          height: "30px",
                          // backgroundColor: "#4CAF50",
                          backgroundColor: colors.blue,
                          border: "none",
                          color: "white",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        Start here
                      </Button>
                    </>
                  ) : null}
                </div>
                {!advancedOption ? null : (
                  <div style={{ maxWidth: 600, width: "100%" }}>
                    <GroupContainer>
                      <Field
                        id={`${fieldIdPrefix}-budget`}
                        name="budget"
                        label={["Your ", <span>Budget</span>]}
                        // min={"0"}
                        component={InputNumber}
                        // type="number"
                        // isRequired
                        isClearable
                      />
                      <Field
                        id={`${fieldIdPrefix}-age`}
                        name="age"
                        label={["Their ", <span>Age</span>]}
                        component={Select}
                        options={ageOptions.map((option) => ({
                          label: option,
                          value: option,
                        }))}
                        isClearable
                        onChange={(
                          option: {
                            value: string;
                          } | null,
                          field: { name: string }
                        ) => {
                          setFieldValue(field.name, option ? option.value : "");

                          // Unset previous likes & dislikes values if set
                          if (values.likes) {
                            setFieldValue("likes", "");
                          }
                          if (values.dislikes) {
                            setFieldValue("dislikes", "");
                          }
                        }}
                      />
                      <Field
                        id={`${fieldIdPrefix}-who`}
                        name="who"
                        label={["Their ", <span>Gender</span>]}
                        component={Select}
                        options={genderOptions.map((option) => ({
                          label: option,
                          value: option,
                        }))}
                        isClearable
                        onChange={(
                          option: {
                            value: string;
                          } | null,
                          field: { name: string }
                        ) => {
                          setFieldValue(field.name, option ? option.value : "");

                          // Unset previous likes & dislikes values if set
                          if (values.likes) {
                            setFieldValue("likes", "");
                          }
                          if (values.dislikes) {
                            setFieldValue("dislikes", "");
                          }
                        }}
                      />
                      <Field
                        id={`${fieldIdPrefix}-location`}
                        name="location"
                        label={["Their ", <span>Country</span>]}
                        component={Select}
                        options={locationOptions.map((option) => ({
                          label: option,
                          value: option,
                        }))}
                        isClearable
                        isRequired
                        onChange={(
                          option: {
                            value: string;
                          } | null,
                          field: { name: string }
                        ) => {
                          setFieldValue(field.name, option ? option.value : "");

                          // Unset previous likes & dislikes values if set
                          if (values.likes) {
                            setFieldValue("likes", "");
                          }
                          if (values.dislikes) {
                            setFieldValue("dislikes", "");
                          }
                        }}
                      />
                    </GroupContainer>

                    {/* Toggle */}
                    <GroupContainer>
                      <GroupTitle>
                        Enhance the gift ideas
                        <Disclaimer>
                          Use one of these options to get better results
                        </Disclaimer>
                      </GroupTitle>
                      <GroupedTabs>
                        <Toggle
                          options={[
                            {
                              label: (
                                <>
                                  Select Their <span>Interests</span>
                                </>
                              ),
                              key: "about",
                              onClick: () => {
                                setWriteItActive(false);
                              },
                              isActive: writeItActive === false,
                            },
                            {
                              label: (
                                <>
                                  Write <span>About Them</span>
                                </>
                              ),
                              key: "likes",
                              onClick: () => {
                                setWriteItActive(true);
                              },
                              isActive: writeItActive === true,
                            },
                          ]}
                        />
                        {writeItActive ? (
                          <Field
                            id={`${fieldIdPrefix}-about`}
                            name="about"
                            // label="About Them (Recommended)"
                            tooltip={`Tell us a little about their personality, values or anything relevant for personalised gift ideas. Example: "They work in marketing. They're interested in fashion, travel and fitness"`}
                            customLabel={
                              <>
                                <SvgPencil />
                                <div>
                                  <p>
                                    Tap here to write <span>About Them</span>
                                    <br />
                                    You could include their unique interests,
                                    hobbies, work, favourite brands or goals.
                                  </p>
                                  {/* You could include their interests, hobbies, what they
                          do for work, favourite brands, their goals, or things
                          they love. */}
                                </div>
                              </>
                            }
                            component={Textarea}
                            containerStyle={{ marginTop: "4px" }}
                          />
                        ) : (
                          <Field
                            id={`${fieldIdPrefix}-likes`}
                            name="likes"
                            // label="Interests (Optional)"
                            tooltip={`Select what they’re interested in from our lists, or type their interests into the About Them field. Less is usually best. The lists will load once you set a gender and age.`}
                            // placeholder={`Select 1-5 options from our Common Interests or What’s Popular lists. What’s Popular requires an age and gender.`}
                            component={Textarea}
                            onClick={() => {
                              setLikesModalActive(true);
                            }}
                            // disabled={isLikesDislikesDisabled}
                            readOnly
                            containerStyle={{ marginTop: "4px" }}
                            customLabel={
                              <>
                                <SvgPlus />
                                <div>
                                  <p>
                                    Tap here to choose their{" "}
                                    <span>Interests</span>
                                  </p>
                                  {/* Select their interests from our Common and What’s
                          Popular lists (What’s Popular requires their Age and
                          Gender ID). */}
                                </div>
                              </>
                            }
                          />
                        )}
                      </GroupedTabs>
                    </GroupContainer>
                    <ButtonContainer>
                      <Button
                        icon="search"
                        type="submit"
                        isLoading={isLoadingSuggestions}
                        loadingData={loadingData}
                        disabled={!isWebsocketReady || isSubmitting}
                      >
                        {hasSubmitted ? "Find more gifts" : "Find gifts"}
                      </Button>
                    </ButtonContainer>
                  </div>
                )}
              </div>
              {/*<Field
                id={`${fieldIdPrefix}-dislikes`}
                name="dislikes"
                label="Dislikes (Optional)"
                tooltip={`Select things that they don't like or may
not like from our list. This helps us 
recommend gifts.`}
                component={Textarea}
                onClick={() => {
                  setDislikesModalActive(true);
                }}
                disabled={isLikesDislikesDisabled}
                readOnly
              />*/}
              {/* Popups */}
              <LikesOrDislikesModal
                modalType="LIKES"
                isModalActive={likesModalActive}
                setModalActive={setLikesModalActive}
                setFieldValue={setFieldValue}
                fieldValue={values.likes}
                fieldKey="likes"
                setLikesOrDislikesSelections={setLikesSelections}
                likesOrDislikesSelections={likesSelections}
                locationValue={values.location || "Australia"}
                genderValue={values.who}
                ageValue={values.age}
                likesDislikesData={likesDislikesData}
                getLikesDislikes={getLikesDislikes}
              />
              <LikesOrDislikesModal
                modalType="DISLIKES"
                isModalActive={dislikesModalActive}
                setModalActive={setDislikesModalActive}
                setFieldValue={setFieldValue}
                fieldValue={values.dislikes}
                fieldKey="dislikes"
                setLikesOrDislikesSelections={setDislikesSelections}
                likesOrDislikesSelections={dislikesSelections}
                locationValue={values.location}
                genderValue={values.who}
                ageValue={values.age}
                likesDislikesData={likesDislikesData}
                getLikesDislikes={getLikesDislikes}
              />
              {errorData?.result ? (
                <Notification
                  title="Error"
                  message={errorData.result.error}
                  color="red"
                  style={{ margin: "30px 0" }}
                />
              ) : null}

              <Disclaimer>&nbsp;</Disclaimer>
              <Disclaimer>
                <span>DISCLAIMER:</span> Givving is in its early stages of
                development. It can and will make mistakes. Contact us if you
                have any questions or concerns.
              </Disclaimer>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
}
