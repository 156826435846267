import { BrowserRouter, Routes as RRDRoutes, Route } from "react-router-dom";
import GiftSuggestionsV1 from "pages/GiftSuggestions/V1";
import GiftSuggestionsV2 from "pages/GiftSuggestions/V2";
import GiftSuggestionsV3 from "pages/GiftSuggestions/V3";
import MoreLikeThis from "pages/GiftSuggestions/MoreLikeThis";

import Error from "pages/Error";
import ResultsPage from "pages/GiftSuggestions/ResultsPage";

function Routes() {
  return (
    <BrowserRouter>
      <RRDRoutes>
        <Route path="/" element={<GiftSuggestionsV3 />} />
        <Route path="/v2" element={<GiftSuggestionsV2 />} />
        <Route path="/v1" element={<GiftSuggestionsV1 />} />
        <Route path="/morelikethis" element={<MoreLikeThis />} />
        <Route path="/search" element={<ResultsPage />} />
        <Route path="*" element={<Error />} />
      </RRDRoutes>
    </BrowserRouter>
  );
}

export default Routes;
