// more like this
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Container,
  Disclaimer,
  GroupContainer,
  GroupedTabs,
  GroupTitle,
  ItemContainer,
  Title,
} from "./style";
import { Header } from "components/Header";
import {
  AffiliateTag,
  BackToTop,
  ResultCtaHeading,
  ResultHeading,
  ResultImgContainer,
  ResultTag,
  ResultText,
  ReviewContainer,
} from "../V3/Results/style";
import { Img } from "components/Img";
import { StarRating } from "components/StarRating";
import { ButtonLink } from "components/ButtonLink";
import { SvgArrow } from "svg/SvgArrow";
import { Button as Btn } from "components/Button";
import { Separator } from "components/Separator";
// import { Button } from "react-scroll";
// import { colors } from "theme";
import backgroundImage from "./filter.png"; // Import the image
import gridBackgroundImage from "./grid.png"; // Import the image
import gridBackgroundImage2 from "./grid2.png"; // Import the image
import listBackgroundImage from "./list.png"; // Import the image
import listBackgroundImage2 from "./list2.png"; // Import the image
import { colors } from "theme";
import { ButtonGridItem } from "components/ButtonGrid";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { websocketAddressV2 } from "utils/constants";
import { isGiftSuggestionsArray } from "../V3/helper";
import { GiftSuggestionsWithId } from "../V3/types";
import { v4 } from "uuid";
import { Loader } from "components/Loader";
import myImage from "./filter.png"; // Adjust the path based on your folder structure

interface UserData {
  products: any;
}

const MoreLikeThis: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false); // State to control modal visibility
  const [isGrid, setIsGrid] = useState(true);
  const [productFilters, setProductFilters] = useState<any[]>([]);
  const [selectedProductFilters, setSelectedProductFilters] = useState<any[]>(
    []
  );
  const [currentIdea, setCurrentIdea] = useState(0);
  const [isGiftIdea, setGiftIdea] = useState(false);
  const [giftSuggestions, setGiftSuggestions] = useState<GiftSuggestionsWithId>(
    []
  );
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024); // Adjust threshold as needed
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this runs only once
  const filterArray: any[] = [];
  const toggleModal = () => {
    setIsOpen(!isOpen); // Toggle the modal visibility
  };
  const toggleViewOption = () => {
    setIsGrid(!isGrid); // Toggle the modal visibility
  };
  const toggleGiftIdea = () => {
    setGiftIdea(!isGiftIdea); // Toggle the modal visibility
  };

  const products = location.state.products; // Get the object passed via state
  const index = location.state.index; // Get the object passed via state
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    websocketAddressV2,
    {
      share: false,
      shouldReconnect: () => true,
    }
  );

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      // Get initial data to power the form fields on ready
      sendJsonMessage({
        config: true,
      });
    }
  }, [readyState, sendJsonMessage]);

  useEffect(() => {
    if (isGiftSuggestionsArray(lastJsonMessage)) {
      const mappedGiftSuggestions: GiftSuggestionsWithId =
        lastJsonMessage.results.map((giftSuggestion) => ({
          ...(giftSuggestion.group && {
            group: giftSuggestion.group,
          }),
          results: giftSuggestion.results
            ? giftSuggestion.results.map((result) => ({
                ...result,
                id: v4(),
              }))
            : [],
          filters: giftSuggestion.filters
            ? giftSuggestion.filters.map((result) => ({
                ...result,
                id: v4(),
              }))
            : [],
          id: v4(),
        }));
      setGiftSuggestions(mappedGiftSuggestions[0]);
      setProductFilters([
        ...getProductFilters(mappedGiftSuggestions[0]?.filters),
      ]);
      setIsLoadingSuggestions(false);
      // if (prevIsLoadingSuggestions) {
      //   scroller.scrollTo("results", {
      //     duration: 500,
      //     smooth: true,
      //     offset: -100,
      //   });
      // }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    setCurrentIdea(index);
  }, []);

  const getProductFilters = (productsFilters: any) => {
    filterArray.length = 0;
    productsFilters?.map((result, i) => {
      const filterOptions: any[] = [];
      result.options?.map((option, j) => {
        filterOptions.push(option.text);
      });
      const type = result.type;
      filterArray.push({ type: type, options: filterOptions });
    });

    return filterArray;
  };

  useEffect(() => {
    setProductFilters([...getProductFilters(products[currentIdea].filters)]);
    setGiftSuggestions(products[currentIdea]);
    setSelectedProductFilters([]);
    setIsOpen(false);
  }, [currentIdea]);

  // useEffect(() => {
  //   giftSuggestions
  //     ? setSelectedGiftSuggestions([...giftSuggestions])
  //     : setSelectedGiftSuggestions([...products[currentIdea].results]);
  // }, [giftSuggestions]);

  // console.log(products);
  // console.log(index);
  const handleGoBack = () => {
    // Go back to the previous page, while keeping the state
    navigate(-1); // This will use the browser's history stack
  };

  const renderItems = (items: any, i: number) => {
    const title = items.title ?? "";
    const seller = items.seller;
    const description = items.description;
    const advert = items.advert;
    const link = items.link ?? "";
    const thumbnail = items.thumbnail;
    const price = items.price;
    const delivery = items.delivery;
    const rating = items.rating;
    const reviews = items.reviews;
    const currency = items.currency;

    return (
      <ItemContainer
        style={{
          width: isDesktop ? "15%" : isGrid ? "45%" : "100%",
          minWidth: 180,
        }}
      >
        {thumbnail ? (
          <ResultImgContainer>
            <Img
              src={Array.isArray(thumbnail) ? thumbnail[0] : thumbnail}
              alt={"title"}
            />
          </ResultImgContainer>
        ) : null}
        <ResultHeading>{title}</ResultHeading>
        {typeof price === "number" || delivery ? (
          <>
            {typeof price === "number" ? (
              <ResultCtaHeading>
                <span>{currency}</span> ${price.toString()}
              </ResultCtaHeading>
            ) : null}
            {delivery ? (
              <ResultCtaHeading>
                {/* <span>Delivery:</span> {getDeliveryValue(delivery)} */}
              </ResultCtaHeading>
            ) : null}
          </>
        ) : null}
        <ReviewContainer>
          {rating ? (
            <>
              {rating} <StarRating rating={rating} /> (
              {(reviews ?? 999) >= 1000
                ? `${((reviews ?? 0) / 1000).toFixed(1).replace(/\.0$/, "")}k`
                : reviews ?? 0}
              )
            </>
          ) : (
            "No reviews yet"
          )}
        </ReviewContainer>
        <ResultTag>
          {advert && <AffiliateTag>affiliate</AffiliateTag>}
          Seller:&nbsp;<span>{seller}</span>
        </ResultTag>
        {description ? <ResultText>{description}</ResultText> : null}
        {link ? (
          <ButtonLink
            href={link}
            onClick={
              () => {}
              // onClickBuyNow({
              //   title,
              //   link,
              // })
            }
            target="_blank"
          >
            View now <SvgArrow />
          </ButtonLink>
        ) : null}
      </ItemContainer>
    );
  };

  const buttonStyle = {
    padding: "9px 0px",
    paddingRight: 15,
    cursor: "pointer",
    // width: 100,
    height: 40,
    // backgroundImage: `url(${backgroundImage})`, // Use the imported image
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    borderRadius: 3,
  };
  const buttonClearStyle = {
    padding: "10px 20px",
    cursor: "pointer",
    width: 100,
    height: 40,
    borderRadius: 3,
    fontSize: 14,
    fontWeight: 600,
    // marginLeft: 10,
  };
  const button2Style = {
    border: "none",
    borderTopLeftRadius: "3px", // Top-left corner border radius
    borderBottomLeftRadius: "3px", // Bottom-left corner border radius
    padding: "10px 20px",
    cursor: "pointer",
    width: 40,
    height: 40,
    color: isGrid ? "red" : "white",
    borderRadius: 0,
    backgroundColor: !isGrid ? colors.blue : colors.disabled, // Set background color
  };
  const button3Style = {
    border: "none",
    borderTopRightRadius: "3px", // Top-left corner border radius
    borderBottomRightRadius: "3px", // Bottom-left corner border radius
    padding: "10px 20px",
    cursor: "pointer",
    width: 40,
    height: 40,
    color: !isGrid ? "red" : "white",
    borderRadius: 0,
    backgroundColor: isGrid ? colors.blue : colors.disabled, // Set background color

    // backgroundImage: `url(${backgroundImage})`, // Use the imported image
    // backgroundSize: "cover",
    // backgroundPosition: "center",
  };

  return (
    <Container>
      {/* <Header /> */}
      <div
        style={{
          display: "flex",
          gap: 10,
          justifyContent: "center", // Horizontally center the content
          alignItems: "center",
          width: "100%",
          height: 84,
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          navigate(-1); // Go back if there's a previous history state
        }}
      >
        <div
          className="swiper-button-prev"
          style={{
            position: "relative",
            top: 0,
            margin: 0,
            left: 0,
            right: 0,
            display: "block",
          }}
        ></div>
        <div>Go back to all gift ideas</div>
      </div>
      {isLoadingSuggestions ? (
        <Loader variant="fixed" backdrop="transparent" />
      ) : null}

      <div
        onClick={toggleGiftIdea}
        style={{
          margin: 16,
          fontSize: 19,
          display: "flex",
          gap: 10,
          justifyContent: isDesktop ? "flex-start" : "flex", // Horizontally center the content
          alignItems: "center",
          flexWrap: "wrap",
          // height: '200px',
          // backgroundColor: 'lightblue',
          // textAlign: 'center',
          // lineHeight: '200px',
          // cursor: 'pointer',
        }}
      >
        <div style={{ color: "#B0B0B0", display: "inline" }}>
          Shop Gift Idea {currentIdea + 1}{" "}
        </div>
        : {products[currentIdea].group}
        <span className="material-symbols-outlined"></span>
      </div>
      {isGiftIdea && (
        <div className="dropdown-modal" style={{ margin: 16 }}>
          {products?.map((result, i) => (
            <div
              onClick={() => {
                setCurrentIdea(i);
                setGiftIdea(false);
              }}
            >
              <p>
                <div style={{ color: "#B0B0B0", display: "inline" }}>
                  Shop Gift Idea
                </div>{" "}
                {i + 1}: {result.group}
              </p>
            </div>
          ))}

          {/* <button onClick={toggleModal} className="close-button">
            Close
          </button> */}
        </div>
      )}
      <Separator />

      <div style={{ margin: 16, display: "flex" }}>
        <div
          style={{ width: isOpen ? "100%" : "50%", display: "flex", gap: 10 }}
        >
          <div style={{ position: "relative" }}>
            {selectedProductFilters.length > 0 ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: 99,
                  right: -5,
                  top: -10,
                  backgroundColor: "white",
                  textAlign: "center",
                  color: colors.blue,
                  fontWeight: 600,
                  minWidth: 20,
                  height: 20,
                  fontSize: 12,
                  borderRadius: 10,
                }}
              >
                {selectedProductFilters.length}
              </div>
            ) : null}

            <Btn
              style={buttonStyle}
              icon="search"
              type="submit"
              onClick={toggleModal}
            >
              <img
                src={myImage} // Replace with your image URL
                alt="icon"
                style={{
                  width: "40px", // Set the width of the image
                  height: "40px", // Set the height of the image
                  // marginRight: "8px", // Space between image and text
                }}
                // style={{ marginRight: '10px' }}
              />
              Filters
            </Btn>
          </div>
          {isOpen ? (
            <Btn
              style={buttonClearStyle}
              icon="search"
              type="submit"
              onClick={() => {
                setProductFilters([
                  ...getProductFilters(products[currentIdea].filters),
                ]);
                setGiftSuggestions(products[currentIdea]);
                setSelectedProductFilters([]);
                setIsOpen(false);
              }}
            >
              Clear all
            </Btn>
          ) : null}
        </div>
        {!isOpen ? (
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {!isDesktop ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Btn
                  style={button2Style}
                  icon="search"
                  type="submit"
                  onClick={toggleViewOption}
                  disabled={!isGrid}
                >
                  <img
                    src={!isGrid ? listBackgroundImage : listBackgroundImage2}
                    alt="icon"
                    style={{
                      width: "20px", // Set the width of the image
                      height: "20px", // Set the height of the image
                      margin: "8px", // Space between image and text
                    }}
                  />
                </Btn>
                <Btn
                  style={button3Style}
                  icon="search"
                  type="submit"
                  onClick={toggleViewOption}
                  disabled={isGrid}
                >
                  <img
                    src={!isGrid ? gridBackgroundImage2 : gridBackgroundImage}
                    alt="icon"
                    style={{
                      width: "20px", // Set the width of the image
                      height: "20px", // Set the height of the image
                      margin: "8px", // Space between image and text
                    }}
                  />
                </Btn>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <div>
        {products ? <div></div> : <p>No Gift ideas.</p>}
        {/* <button onClick={handleGoBack}>Go Back</button> */}
      </div>

      <div
        style={{
          display: isDesktop ? "flex" : "block",
          alignItems: "flex-start", // Ensures columns don't stretch to same height
        }}
      >
        <>
          {isOpen && (
            <div
              className="dropdown-modal"
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                width: isDesktop ? "35%" : "100%",
                maxWidth: isDesktop ? 400 : undefined,
              }}
            >
              <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
                {selectedProductFilters?.map((selectedFilter, i) => (
                  // <>{selectedFilter}</>
                  <ButtonGridItem
                    style={{ minHeight: 40, width: "auto" }}
                    $isActive={true}
                    $hasLabel={true}
                    type="button"
                    onClick={() => {
                      console.log("pressed");
                    }}
                    // key={option.id}
                  >
                    {selectedFilter}
                  </ButtonGridItem>
                ))}
              </div>
              {productFilters?.map((result, i) =>
                result.type ? (
                  <>
                    <p style={{ fontSize: 15, fontWeight: 600 }}>
                      {result.type}
                    </p>
                    <Separator />
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
                      {result.options?.map((option, j) => (
                        <ButtonGridItem
                          style={{ minHeight: 40, width: "auto" }}
                          $isActive={false}
                          $hasLabel={true}
                          type="button"
                          onClick={() => {
                            setIsLoadingSuggestions(true);
                            setIsOpen(false);

                            const pIndex =
                              selectedProductFilters.length > 0
                                ? 0
                                : currentIdea;

                            // arr.push(option);
                            setSelectedProductFilters([
                              ...selectedProductFilters,
                              option,
                            ]);

                            sendJsonMessage({
                              more: giftSuggestions?.filters[i].options[j]
                                .serpapi_link,
                            });
                            window.scrollTo(0, 0); // Scroll to the top on route change
                          }}
                        >
                          {option}
                        </ButtonGridItem>
                      ))}
                    </div>
                  </>
                ) : null
              )}
            </div>
          )}
        </>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            // flex: undefined,
            flex: isDesktop ? 1 : undefined,
            paddingLeft: isDesktop ? 15 : 0,
            gap: isDesktop ? 20 : 10,
            justifyContent: isDesktop ? "left" : "center",
            // display: 'flex',
            // justifyContent: 'space-between',
            alignItems: "flex-start", // Ensures columns don't stretch to same height
          }}
        >
          {giftSuggestions?.results?.map((result, i) => renderItems(result, i))}

          <div
            style={{
              //   padding: 30,
              //   background: colors.field,
              width: "100%",
              //   fontWeight: 600,
              //   fontSize: 16,
              //   textAlign: "center",
              //   borderRadius: 10,
              //   marginBottom: 20,
              // marginRight: isDesktop ? 0 : 20,
              // marginLeft: isDesktop ? 0 : 20,
            }}
          >
            <div
              style={{
                padding: 30,
                background: colors.field,
                width: "100%",
                fontWeight: 600,
                fontSize: 16,
                textAlign: "center",
                borderRadius: 10,
                marginBottom: 20,
                marginRight: 20,
                // marginLeft: isDesktop ? 0 : 20,
              }}
            >
              Try using our filter at the top of the page to get more and
              different results for this gift idea
            </div>
            <BackToTop
              onClick={() => {
                window.scrollTo(0, 0); // Scroll to the top on route change
              }}
            >
              Back to top
            </BackToTop>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MoreLikeThis;
