// more like this
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Container,
  Disclaimer,
  GroupContainer,
  GroupedTabs,
  GroupTitle,
  ItemContainer,
  Title,
} from "./style";
import { Header } from "components/Header";
import {
  AffiliateTag,
  ResultCtaHeading,
  ResultHeading,
  ResultImgContainer,
  ResultTag,
  ResultText,
  ReviewContainer,
} from "../V3/Results/style";
import { Element, scroller } from "react-scroll";

import { Img } from "components/Img";
import { StarRating } from "components/StarRating";
import { ButtonLink } from "components/ButtonLink";
import { SvgArrow } from "svg/SvgArrow";
import { Button as Btn } from "components/Button";
import { Separator } from "components/Separator";
// import { Button } from "react-scroll";
// import { colors } from "theme";
import backgroundImage from "./filter.png"; // Import the image
import gridBackgroundImage from "./grid.png"; // Import the image
import gridBackgroundImage2 from "./grid2.png"; // Import the image
import listBackgroundImage from "./list.png"; // Import the image
import listBackgroundImage2 from "./list2.png"; // Import the image
import { colors } from "theme";
import { ButtonGridItem } from "components/ButtonGrid";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { websocketAddressV2 } from "utils/constants";
import { isGiftSuggestionsArray } from "../V3/helper";
import { GiftSuggestionsWithId } from "../V3/types";
import { v4 } from "uuid";
import { Loader } from "components/Loader";
import { Results } from "../V3/Results";

const ResultsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const mappedGiftSuggestions = location.state?.mappedGiftSuggestions; // Get the object passed via state

  // Retrieve or generate a unique device_id
  const [deviceId, setDeviceId] = useState<string>(() => {
    const storedDeviceId = localStorage.getItem("device_id");
    if (storedDeviceId) {
      return storedDeviceId;
    } else {
      const newDeviceId = v4();
      localStorage.setItem("device_id", newDeviceId);
      setDeviceId(newDeviceId);
      return newDeviceId;
    }
  });

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    websocketAddressV2,
    {
      share: false,
      shouldReconnect: () => true,
    }
  );

  function sendGiftBuyNowNotification({
    title,
    link,
  }: {
    title: string;
    link: string;
  }) {
    const queryData = {
      buy: true,
      query: {
        title,
        link,
        device_id: deviceId,
      },
    };
    console.log("SEND:");
    console.log(queryData);
    sendJsonMessage(queryData);
  }

  return (
    <Container>
      {/* <Header /> */}
      <div
        style={{
          display: "flex",
          gap: 10,
          justifyContent: "center", // Horizontally center the content
          alignItems: "center",
          width: "100%",
          height: 84,
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          navigate(-1); // Go back if there's a previous history state
        }}
      >
        <div
          className="swiper-button-prev"
          style={{
            position: "relative",
            top: 0,
            margin: 0,
            left: 0,
            right: 0,
            display: "block",
          }}
        ></div>
        <div>Go back to gift search</div>
      </div>
      <Element name="results">
        <Results
          giftSuggestions={mappedGiftSuggestions}
          sendGiftBuyNowNotification={sendGiftBuyNowNotification}
        />
      </Element>
    </Container>
  );
};

export default ResultsPage;
